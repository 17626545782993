import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import i18n from 'i18next';
import {
  AgilityGrid,
  AgilityRadioGroup,
  AgilitySelectField,
  AgilityTypography,
  AgilityTextField,
  AgilityAutocomplete,
  AgilityInfo,
  AgilityCheckbox,
  AgilityFormGroup,
} from 'Src/AgilityComponents';
import AgilityDialog from 'App/components/Dialog/AgilityDialog';
import VsiFields from './VsiFields';
import {
  STREET_ADDRESS_MAX_LENGTH,
  SUBURB_MAX_LENGTH,
  POST_CODE_MAX_LENGTH,
  METER_ACCESS_OBSTACLES_TELL_US_MORE_MAX_LENGTH,
  METER_ACCESS_RISKS_TELL_US_MORE_MAX_LENGTH,
} from 'App/utils/fieldLengthHelper';
import {
  convertDateFromISO,
  convertToDateOnly,
  isEmpty,
  isToday,
} from 'App/utils/helper';
import { array, func, object, bool } from 'prop-types';
import {
  noYesOptions,
  propertyPlanOption,
  yesNoNotSureOptionsProperty,
  ownerType,
  yesNoNotSureOptions,
} from 'App/utils/data';
import { scroller, Element } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  allowsSameDayFuelInjections,
  showSolarType,
  disableRentOwnQuestion,
} from 'App/customConfig';
import { TRANSFER_TYPE } from 'App/utils/constants';
import { useSelector } from 'react-redux';
import dompurify from 'dompurify';
const sanitizer = dompurify.sanitize;

const IS_YES_SELECTED = 'yes';

const PropertyLayout = props => {
  const {
    stateList,
    solarTypeList,
    coordinatorsList,
    isActive,
    errors,
    handleChange,
    fields,
    moveInDatesList,
    moveInError,
    onMoveInError,
    erroredField,
    customConfig,
    signupAPIResponse,
  } = props;

  const [showAcceptMoveInTermsDialog, setShowAcceptMoveInTermsDialog] =
    useState(false);

  const handleMoveInTermsAcceptance = (val, name) => {
    console.log('handleMoveInTermsAcceptance');
    // check/uncheck the checkbox
    handleChange(val, name);
    // close the popup
    setShowAcceptMoveInTermsDialog(false);
  };

  // get current state name form redux
  const currentState = useSelector(
    state => state?.onBoarding?.primarySiteIdentifier?.address?.State
  );

  const [moveInTipHtml, setMoveInTipHtml] = useState(
    sanitizer(
      i18n.t('yourProperty.moveInDates.sdfi', {
        phoneNumber: process.env.REACT_APP_COMPANY_URGENT_CONNECTIONS_PHONE
          ? process.env.REACT_APP_COMPANY_URGENT_CONNECTIONS_PHONE
          : process.env.REACT_APP_COMPANY_PHONE,
        interpolation: { escapeValue: false },
      })
    )
  );

  const [transferTipHtml, settransferTipHtml] = useState(
    sanitizer(
      i18n.t('youProperty.transfer.tip', {
        phoneNumber: process.env.REACT_APP_COMPANY_URGENT_CONNECTIONS_PHONE
          ? process.env.REACT_APP_COMPANY_URGENT_CONNECTIONS_PHONE
          : process.env.REACT_APP_COMPANY_PHONE,
        interpolation: { escapeValue: false },
      })
    )
  );

  useEffect(() => {
    if (erroredField.invalid) {
      scroller.scrollTo(erroredField.invalid, {
        duration: 1000,
        delay: 10,
        smooth: 'easeInOutQuart',
        isDynamic: true,
        top: 0,
        offset: -220, // Scrolls to element + 220 pixels down the page
      });
    }
  }, [erroredField]);

  useEffect(() => {
    if (
      isEmpty(fields.solarType) &&
      Array.isArray(solarTypeList) &&
      solarTypeList.length > 0
    ) {
      handleChange(solarTypeList[0].value, 'solarType');
    }
  }, [fields.solarType, handleChange, solarTypeList]);

  useEffect(() => {
    if (!isEmpty(signupAPIResponse)) {
      const transferDetail = signupAPIResponse.transferDetail;
      if (!isEmpty(transferDetail)) {
        if (
          transferDetail.transferType === TRANSFER_TYPE.MOVE_IN &&
          customConfig.showConnectionTermsAndConditions
        ) {
          // @TODO this is broken still
          handleChange(transferDetail.acceptMoveInTerms, 'acceptMoveInTerms');
        }
      }

      if (
        signupAPIResponse.address &&
        signupAPIResponse.address.nmiStatus === 'D' &&
        signupAPIResponse.address.State === 'QLD' &&
        isEmpty(fields.isVsiRequirement)
      ) {
        handleChange(true, 'isVsiRequirement');
      }
    }
  }, [fields.isVsiRequirement, handleChange, signupAPIResponse]);

  useEffect(() => {
    // It's just 1st energy requirement
    if (
      process.env.REACT_APP_BRAND_CODE === 'FIRST' &&
      currentState === 'NSW'
    ) {
      setMoveInTipHtml(
        sanitizer(
          i18n.t('yourProperty.moveInDates.sdfi.nsw', {
            phoneNumber: process.env.REACT_APP_COMPANY_PHONE,
            interpolation: { escapeValue: false },
          })
        )
      );
    }
  }, [moveInTipHtml, currentState]);

  const allowedDates = date => {
    return !moveInDatesList.includes(convertDateFromISO(date));
  };

  const handleMoveInTermsClick = () => {
    // on clicking (or reclicking) the checkbox, set the value to false
    handleMoveInTermsAcceptance(false, 'acceptMoveInTerms');
    setShowAcceptMoveInTermsDialog(true);
  };

  return (
    <React.Fragment>
      <AgilityGrid container>
        <AgilityGrid item xs={12} sm={12}>
          <div className="question-wrapper">
            {fields.transferType !== TRANSFER_TYPE.NEW_CONNECTION && (
              <div>
                <AgilityTypography
                  variant="body2"
                  component="p"
                  tooltipText={i18n.t('yourProperty.plan.info')}
                  showInfo={true}
                >
                  {i18n.t('yourProperty.plan.placeholder')}
                </AgilityTypography>
                <Element name="transferType">
                  <AgilityRadioGroup
                    disabled={!isActive}
                    className="two-line"
                    options={propertyPlanOption}
                    onChange={event => {
                      handleChange(event.target.value, 'transferType');
                    }}
                    value={fields.transferType || ''}
                    data-test-id="transferType"
                    row
                    isError={errors && errors.transferType ? true : false}
                    helperText={
                      errors && errors.transferType ? errors.transferType : ''
                    }
                  />
                </Element>
              </div>
            )}
          </div>
        </AgilityGrid>
        <AgilityGrid container>
          {fields.transferType === TRANSFER_TYPE.NEW_CONNECTION && (
            <AgilityGrid item xs={12} sm={12}>
              <AgilityInfo
                severity="warning"
                color="info"
                className="alert-wrapper"
                icon={
                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                }
              >
                <AgilityTypography
                  variant="body2"
                  component="p"
                  className="mt-0"
                  dangerouslySetInnerHTML={{
                    __html: i18n.t('youProperty.newConnection.tip'),
                  }}
                ></AgilityTypography>
              </AgilityInfo>
            </AgilityGrid>
          )}
          {fields.transferType === TRANSFER_TYPE.TRANSFER &&
            transferTipHtml && (
              <AgilityGrid item xs={12} sm={12}>
                <AgilityInfo
                  severity="warning"
                  color="info"
                  className="alert-wrapper"
                  icon={
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                  }
                >
                  <AgilityTypography
                    variant="body2"
                    component="p"
                    className="mt-0"
                    dangerouslySetInnerHTML={{
                      __html: transferTipHtml,
                    }}
                  ></AgilityTypography>
                </AgilityInfo>
              </AgilityGrid>
            )}
          {fields.transferType === TRANSFER_TYPE.MOVE_IN && (
            <React.Fragment>
              <AgilityGrid item xs={12} sm={9}>
                <div className="text-field-wrapper">
                  <Element name="transferDate">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        disabled={!isActive}
                        fullWidth
                        autoOk
                        disablePast
                        variant="inline"
                        inputVariant="outlined"
                        format={window.dateFormat}
                        id="date-picker-inline"
                        data-test-id="transferDate"
                        label={i18n.t('yourProperty.planMoveIn.placeholder', {
                          dateFormat: window.dateFormat.toUpperCase(),
                        })}
                        value={fields.transferDate || null}
                        onChange={val =>
                          handleChange(convertToDateOnly(val), 'transferDate')
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        shouldDisableDate={allowedDates}
                        onError={onMoveInError}
                        required
                        helperText={
                          (errors && errors.transferDate) || moveInError
                            ? errors.transferDate || moveInError
                            : ''
                        }
                        error={
                          (errors && errors.transferDate) || moveInError
                            ? true
                            : false
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Element>
                </div>
              </AgilityGrid>
              <AgilityGrid item xs={12} sm={12}>
                {i18n.t('yourProperty.moveInDates.electricityNoticeRequired')}
                <br />
                {i18n.t('yourProperty.moveInDates.gasNoticeRequired')}
              </AgilityGrid>
              {!allowsSameDayFuelInjections && (
                <AgilityGrid item xs={12} sm={12}>
                  <AgilityInfo
                    severity="warning"
                    color="warning"
                    className="alert-wrapper"
                    icon={
                      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                    }
                  >
                    <AgilityTypography
                      variant="body2"
                      component="p"
                      className="mt-0"
                      dangerouslySetInnerHTML={{
                        __html: moveInTipHtml,
                      }}
                    ></AgilityTypography>
                  </AgilityInfo>
                </AgilityGrid>
              )}
              {allowsSameDayFuelInjections && isToday(fields.transferDate) && (
                <AgilityGrid item xs={12} sm={12}>
                  <AgilityInfo
                    severity="info"
                    color="info"
                    icon={
                      <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                    }
                  >
                    <AgilityTypography
                      variant="body2"
                      component="p"
                      className="mt-0"
                    >
                      {i18n.t('yourProperty.moveInDates.sdfi.deadline')}
                    </AgilityTypography>
                  </AgilityInfo>
                </AgilityGrid>
              )}
              {fields.isVsiRequirement && <VsiFields {...props}></VsiFields>}
              {customConfig.showConnectionTermsAndConditions && (
                <>
                  <AgilityGrid item xs={12} sm={12}>
                    <AgilityFormGroup row className="custom-checkbox-row">
                      <AgilityCheckbox
                        className="custom"
                        data-test-id="acceptMoveInTerms"
                        id="acceptMoveInTerms"
                        color="primary"
                        checked={fields.acceptMoveInTerms || false}
                        onClick={handleMoveInTermsClick}
                      />
                      <AgilityTypography
                        variant="subtitle1"
                        component="label"
                        htmlFor="acceptMoveInTerms"
                        data-test-id="acceptMoveInTerms-label"
                        className={`desc ${
                          errors.acceptMoveInTerms && 'has-error'
                        }`}
                      >
                        {i18n.t('yourProperty.moveInDates.acceptTerms')}
                      </AgilityTypography>
                    </AgilityFormGroup>
                  </AgilityGrid>
                  <AgilityDialog
                    data-test-id="acceptMoveInTerms-dialog"
                    className="full-page-popup-sm move-in-terms-condition-dialog"
                    open={showAcceptMoveInTermsDialog}
                    okClick={() =>
                      handleMoveInTermsAcceptance(true, 'acceptMoveInTerms')
                    }
                    onDismiss={() =>
                      handleMoveInTermsAcceptance(false, 'acceptMoveInTerms')
                    }
                    maxWidth="md"
                    title={i18n.t('yourProperty.acceptTerms.header')}
                    okText={i18n.t('yourProperty.moveInDates.acceptTerms')}
                    contents={[
                      {
                        id: 'accept-move-in-terms-text',
                        body: i18n.t('yourProperty.acceptTerms.body', {
                          interpolation: { escapeValue: false },
                          PHONE: process.env.REACT_APP_CONNECTION_PHONE,
                          PHONE_WITHOUT_SPACES:
                            process.env.REACT_APP_CONNECTION_PHONE.replace(
                              /\s/g,
                              ''
                            ),
                          URL: process.env.REACT_APP_ADDITIONAL_CHARGES_URL,
                          URL_WITHOUT_PROTOCOL:
                            process.env.REACT_APP_ADDITIONAL_CHARGES_URL.replace(
                              /^(http|https):\/\//g,
                              ''
                            ),
                        }),
                        variant: 'subtitle1',
                        element: 'label',
                      },
                    ]}
                  />
                </>
              )}
            </React.Fragment>
          )}
          {fields.isPowerOptionAvailable && customConfig.showHasConnection && (
            <AgilityGrid item xs={12} sm={12}>
              <div className="question-wrapper">
                <AgilityTypography variant="body2" component="p">
                  {i18n.t('yourProperty.isPowerOn.placeholder')}
                </AgilityTypography>
                <Element name="hasConnection">
                  <AgilityRadioGroup
                    disabled={!isActive}
                    options={yesNoNotSureOptionsProperty}
                    onChange={event => {
                      handleChange(event.target.value, 'hasConnection');
                    }}
                    value={fields.hasConnection || ''}
                    data-test-id="hasConnection"
                    row
                    isError={errors && errors.hasConnection ? true : false}
                    helperText={
                      errors && errors.hasConnection ? errors.hasConnection : ''
                    }
                  />
                </Element>
              </div>
            </AgilityGrid>
          )}
        </AgilityGrid>
      </AgilityGrid>

      <AgilityGrid container>
        <AgilityGrid item xs={12} sm={12}>
          <div className="question-wrapper">
            <AgilityTypography variant="body2" component="p">
              {i18n.t('yourProperty.postalAddress.placeholder')}
            </AgilityTypography>
          </div>
        </AgilityGrid>
        <AgilityGrid container spacing={2}>
          <AgilityGrid item xs={12} sm={6}>
            <Element name="postal_street_address">
              <AgilityTextField
                disabled={!isActive}
                id="postal_street_address"
                data-test-id="postal_street_address"
                type="text"
                fullWidth
                placeholder={i18n.t('yourProperty.streetAddress.placeholder')}
                value={fields.streetAddress || ''}
                onChange={val => handleChange(val, 'streetAddress')}
                variant="outlined"
                required={true}
                inputProps={{ maxLength: STREET_ADDRESS_MAX_LENGTH }}
                error={errors && errors.streetAddress ? true : false}
                helperText={
                  errors && errors.streetAddress ? errors.streetAddress : ''
                }
              />
            </Element>
          </AgilityGrid>
          <AgilityGrid item xs={12} sm={6}>
            <Element name="suburb">
              <AgilityTextField
                disabled={!isActive}
                id="suburb"
                data-test-id="suburb"
                type="text"
                fullWidth
                placeholder={i18n.t('yourProperty.suburb.placeholder')}
                value={fields.suburb || ''}
                onChange={val => handleChange(val, 'suburb')}
                variant="outlined"
                inputProps={{ maxLength: SUBURB_MAX_LENGTH }}
                required={true}
                error={errors && errors.suburb ? true : false}
                helperText={errors && errors.suburb ? errors.suburb : ''}
              />
            </Element>
          </AgilityGrid>
        </AgilityGrid>
        <AgilityGrid container spacing={2}>
          <AgilityGrid item xs={12} sm={6}>
            <Element name="state">
              <AgilitySelectField
                disabled={!isActive}
                data-test-id="state"
                options={stateList}
                variant="outlined"
                labelId="demo-simple-select-filled-label"
                value={fields.state || ''}
                showlabel={true}
                required={true}
                placeholder={`${i18n.t('yourProperty.state.placeholder')} *`}
                onChange={event => handleChange(event.target.value, 'state')}
                isError={errors && errors.state ? true : false}
                helperText={errors && errors.state ? errors.state : ''}
                MenuProps={{
                  PaperProps: { square: true },
                }}
              />
            </Element>
          </AgilityGrid>
          <AgilityGrid item xs={12} sm={6}>
            <Element name="postcode">
              <AgilityTextField
                disabled={!isActive}
                id="postcode"
                data-test-id="postcode"
                type="text"
                fullWidth
                placeholder={i18n.t('yourProperty.postcode.placeholder')}
                value={fields.postcode || ''}
                onChange={val => handleChange(val, 'postcode')}
                variant="outlined"
                required={true}
                inputProps={{ maxLength: POST_CODE_MAX_LENGTH }}
                error={errors && errors.postcode ? true : false}
                helperText={errors && errors.postcode ? errors.postcode : ''}
              />
            </Element>
          </AgilityGrid>
        </AgilityGrid>
      </AgilityGrid>

      <AgilityGrid container>
        {!disableRentOwnQuestion && (
          <AgilityGrid item xs={12} sm={12}>
            <div className="question-wrapper">
              <AgilityTypography variant="body2" component="p">
                {i18n.t('yourProperty.ownertype.placeholder')}
              </AgilityTypography>
              <Element name="propertyOwner">
                <AgilityRadioGroup
                  disabled={!isActive}
                  options={ownerType}
                  onChange={event => {
                    handleChange(event.target.value, 'propertyOwner');
                  }}
                  value={fields.propertyOwner || ''}
                  data-test-id="propertyOwner"
                  row
                  isError={errors && errors.propertyOwner ? true : false}
                  helperText={
                    errors && errors.propertyOwner ? errors.propertyOwner : ''
                  }
                />
              </Element>
            </div>
          </AgilityGrid>
        )}
        {fields.hasSolarPanels && showSolarType && (
          <AgilityGrid item xs={12} sm={6}>
            <div className="question-wrapper">
              <AgilityTypography variant="body2" component="p">
                {i18n.t('yourProperty.solarType.label')}
              </AgilityTypography>
              <Element name="solarType">
                <AgilitySelectField
                  disabled={!isActive}
                  data-test-id="solarType"
                  options={solarTypeList}
                  variant="outlined"
                  value={fields.solarType || ''}
                  showlabel={true}
                  required={true}
                  placeholder={`${i18n.t(
                    'yourProperty.solarType.placeholder'
                  )} *`}
                  onChange={event =>
                    handleChange(event.target.value, 'solarType')
                  }
                  isError={errors && errors.solarType ? true : false}
                  helperText={
                    errors && errors.solarType ? errors.solarType : ''
                  }
                  MenuProps={{
                    PaperProps: { square: true },
                  }}
                />
              </Element>
            </div>
          </AgilityGrid>
        )}
      </AgilityGrid>

      {customConfig.showAnyObstacles && (
        <AgilityGrid container>
          <AgilityGrid item xs={12} sm={12}>
            <div className="question-wrapper">
              <AgilityTypography
                variant="body2"
                component="p"
                showInfo={true}
                tooltipText={i18n.t('yourProperty.obstacles.info')}
              >
                {i18n.t('yourProperty.obstacles.placeholder')}
              </AgilityTypography>
              <Element name="hasObstacles">
                <AgilityRadioGroup
                  disabled={!isActive}
                  options={yesNoNotSureOptions}
                  onChange={event => {
                    handleChange(event.target.value, 'hasObstacles');
                  }}
                  value={fields.hasObstacles || ''}
                  data-test-id="hasObstacles"
                  row
                  isError={errors && errors.hasObstacles ? true : false}
                  helperText={
                    errors && errors.hasObstacles ? errors.hasObstacles : ''
                  }
                />
              </Element>
            </div>
          </AgilityGrid>
          {fields.hasObstacles === IS_YES_SELECTED && (
            <AgilityGrid item xs={12}>
              <Element name="obstacles">
                <AgilityTextField
                  disabled={!isActive}
                  id="obstacles"
                  data-test-id="obstacles"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('yourProperty.tellUsMore.placeholder')}
                  value={fields.obstacles || ''}
                  onChange={val => handleChange(val, 'obstacles')}
                  variant="outlined"
                  error={errors && errors.obstacles ? true : false}
                  inputProps={{
                    maxLength: METER_ACCESS_OBSTACLES_TELL_US_MORE_MAX_LENGTH,
                  }}
                  helperText={
                    errors && errors.obstacles ? errors.obstacles : ''
                  }
                />
              </Element>
            </AgilityGrid>
          )}
        </AgilityGrid>
      )}

      {customConfig.showAnyRisks && (
        <AgilityGrid container>
          <AgilityGrid item xs={12} sm={12}>
            <div className="question-wrapper">
              <AgilityTypography
                variant="body2"
                component="p"
                tooltipText={i18n.t('yourProperty.risks.info')}
                showInfo={true}
              >
                {i18n.t('yourProperty.risks.placeholder')}
              </AgilityTypography>
              <Element name="hasRisks">
                <AgilityRadioGroup
                  disabled={!isActive}
                  options={yesNoNotSureOptions}
                  onChange={event => {
                    handleChange(event.target.value, 'hasRisks');
                  }}
                  value={fields.hasRisks || ''}
                  data-test-id="hasRisks"
                  row
                  isError={errors && errors.hasRisks ? true : false}
                  helperText={errors && errors.hasRisks ? errors.hasRisks : ''}
                />
              </Element>
            </div>
          </AgilityGrid>

          {fields.hasRisks === IS_YES_SELECTED && (
            <AgilityGrid item xs={12}>
              <Element name="risks">
                <AgilityTextField
                  disabled={!isActive}
                  id="risks"
                  data-test-id="risks"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('yourProperty.tellUsMore.placeholder')}
                  value={fields.risks || ''}
                  onChange={val => handleChange(val, 'risks')}
                  variant="outlined"
                  inputProps={{
                    maxLength: METER_ACCESS_RISKS_TELL_US_MORE_MAX_LENGTH,
                  }}
                  error={errors && errors.risks ? true : false}
                  helperText={errors && errors.risks ? errors.risks : ''}
                />
              </Element>
            </AgilityGrid>
          )}
        </AgilityGrid>
      )}

      {customConfig.showMeterQuestion && (
        <AgilityGrid container>
          <AgilityGrid item xs={12} sm={12}>
            <div className="question-wrapper">
              <AgilityTypography
                variant="body2"
                component="p"
                tooltipText={i18n.t('yourProperty.preferredCoordinator.info')}
                showInfo={true}
              >
                {i18n.t('yourProperty.preferredCoordinator.placeholder')}
              </AgilityTypography>
              <Element name="hasPreferredMeterReader">
                <AgilityRadioGroup
                  disabled={!isActive}
                  options={noYesOptions}
                  onChange={event => {
                    handleChange(event.target.value, 'hasPreferredMeterReader');
                  }}
                  value={fields.hasPreferredMeterReader || ''}
                  data-test-id="hasPreferredMeterReader"
                  row
                  isError={
                    errors && errors.hasPreferredMeterReader ? true : false
                  }
                  helperText={
                    errors && errors.hasPreferredMeterReader
                      ? errors.hasPreferredMeterReader
                      : ''
                  }
                />
              </Element>
            </div>
          </AgilityGrid>

          {fields.hasPreferredMeterReader === IS_YES_SELECTED && (
            <AgilityGrid item sm={12}>
              <AgilityTypography
                variant="subtitle1"
                component="p"
                color="textPrimary"
                className="mb-1"
              >
                {i18n.t('yourProperty.coordinator.description')}
              </AgilityTypography>
              <Element name="preferredMeterReader">
                <AgilityAutocomplete
                  disabled={!isActive}
                  data-test-id="meter-coordinator"
                  options={coordinatorsList || []}
                  value={fields.preferredMeterReader || {}}
                  onChange={(event, value) =>
                    handleChange(value, 'preferredMeterReader')
                  }
                  isError={errors && errors.preferredMeterReader ? true : false}
                  getOptionLabel={option =>
                    option && option.label ? option.label : ''
                  }
                  renderInput={params => (
                    <AgilityTextField
                      {...params}
                      placeholder={`${i18n.t(
                        'yourProperty.coordinator.placeholder'
                      )} *`}
                      variant="outlined"
                      fullWidth
                      error={
                        errors && errors.preferredMeterReader ? true : false
                      }
                      helperText={
                        errors && errors.preferredMeterReader
                          ? errors.preferredMeterReader
                          : ''
                      }
                    />
                  )}
                />
              </Element>
            </AgilityGrid>
          )}
        </AgilityGrid>
      )}
    </React.Fragment>
  );
};

PropertyLayout.propTypes = {
  isActive: bool,
  stateList: array,
  solarTypeList: array,
  coordinatorsList: array,
  errors: object,
  handleChange: func,
  fields: object,
  moveInDatesList: array,
  customConfig: object,
  signupAPIResponse: object,
};
PropertyLayout.defaultProps = {
  isActive: false,
  stateList: [],
  solarTypeList: [],
  coordinatorsList: [],
  errors: {},
  fields: {},
  moveInDatesList: [],
  customConfig: {},
  signupAPIResponse: {},
};

export default PropertyLayout;
